$bk: #444 !default;
$wh: #fff !default;
$tc: #1e487b !default;
$tc2: #ed1c24 !default;

$lg: #f2f1ef !default;
$mg: #959595 !default;
$dg: #5c5c5c !default;
$ddg: #353536 !default;
$lb: #5c97bf !default;
$bc: #c4c4c4 !default;
$lbc: #ededed !default;
$b: #2d5cb8 !default;
$gr: #BEBEBE !default;
$bl: #3E7CB4 !default;

$f: 'Open Sans', sans-serif !default;
$hf: 'Rubik', sans-serif !default;
$fa: "FontAwesome" !default;


@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx.scss";

@import "app/custom";
@import "opencart/responsive";