@media screen and (min-width: 1650px) {
	.pav-megamenu {
		.navbar-nav {
			& > li {
				padding: 0 10px !important;
			}
		}
	}
}
@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	#mainmenutop {
		.navbar-header {
			width: 100%;
			#bs-megamenu {
				.megamenu {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 10px;
		font-size: 14px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.nav-search {
			margin-bottom: 7px;
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.logo-store {
		img {
			margin-top: 4px;
		}
	}
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#searchtop {
		margin-top: 5px;
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 3 - 13px);
				margin: 5px;
			}
			width: calc(100% / 3 - 13px);
		}
	}
	.header-bottom {
		& > .container {
			height: 100px;
		}
		.logo-container {
			text-align: center;
			flex: 0 0 200px;
			order: 1;
			img {
				max-width: 100%;
			}
		}
		#topcontacts {
			order: 2;
		}
		.headercart {
			order: 3;
			flex: 0 1 150px;
			min-width: 150px;
		}
	}
	.header-menu > .container {
		padding-left: 0;
	}
	#powered {
		.copyright {
			order: 2;
			text-align: center;
			margin: auto;
		}
		.payment {
			order: 1;
			text-align: center;
			.payment-img {
				float: none !important;
				max-width: 80%;
				display: inline-block;
			}
		}
	}
	body .mfilter-free-container {
		top: 0 !important;
		padding: 0 !important;
		width:395px;
		margin-left: -400px;
		margin-right: auto;
		right: auto;
		left: 0;
		border-color: $bc;
		&.mfilter-direction-rtl {
			margin-right: -222px;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0;
			border: 0;
			padding: 9px 5px;
			top: 1px;
			right: 1px;
			width: 33px;
			height: 23px;
			&:before {
				content: '\2716';
				font-family: $fa;
				color: $bl;
				font-size: 28px;
				line-height: 23px;
				padding: 3px;
				left: -1px;
				position: relative;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 65px;
			min-width: 65px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.h1_container {
		&:before {
			margin: 33px 10px 10px 0;
		}
		h1 {
			padding: 18px 15px 21px 0;
			font-size: 26px;
		}
	}
	.product-compare-header {
		width: 60px;
		position: relative;
		flex: 0;
		padding: 13px 10px 0 14px;
		a {
			color: $bk;
			&:hover {
				text-decoration: none;
			}
		}
		.badge {
			right: 7px;
		}
	}
	#cart {
		flex: 0;
		border-left: 1px solid $wh;
		.icon-cart {
			padding: 0 17px;
			height: 50px;
			i {
				line-height: initial;
				vertical-align: -25px;
			}
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.main-menu {
		.canvas-menu {
			color: $wh;
			padding: 0 15px;
		}
	}
	.tree-menu ul ul li {
		padding: 2px 0;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
	#Cart {
		#buy_form_container {
			.buy_products {
				.shopping-cart-table {
					.column-name {
						width: 30%;
					}
					.column-quantity {
						display: flex;
						flex-wrap: wrap;
						.qty {
							display: flex;
							width: 120px;
						}
					}
				}
			}
		}
	}
	.featured-cats {
		.featured-category-list {
			.category-list-item {
				.caption {
					padding: 30px 20px;
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
	#footer {
		ul {
			li {
				.link-direktor {
					background: #3E7CB4;
					color: #fff!important;
					border: 0;
					padding: 7px 0;
					text-align: center;
				}
			}
		}
	}

	#Cart {
		#buy_form_container {
			.buy_products {
				.shopping-cart-table {
					tr {
						td {
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	.product-markdown-view {
		.storage_widget {
			margin: 25px 0 20px 0;
		}
	}

	.product-grid {
		.product-block {
			width: calc(100% / 2 - 12px);
		}
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.h1_container {
		&:before {
			content: none;
		}
		h1 {
			width: 100%;
			font-size: 20px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	#Cart {
		#buy_form_container {
			.buy_products {
				.shopping-cart-table {
					& > thead {
						display: none;
					}
					.shopping-cart-table__product-image img {
						max-width: 160px;
					}
					td.image-col {
						width: 100%;
						text-align: center;
					}
					&, & tbody, & td, & tr {
						display: block;
						border: 0;
						width: 100%;
					}
					tr {
						margin-bottom: 15px;
						border-bottom: 1px solid $bl;
						&:last-child {
							border:0;
						}
						&:after {
							content: "";
							display: block;
							clear: both;
						}
					}
					td {
						position: relative;
						text-align: left;
						width: calc(100% - 120px) !important;
						display: flex;
						flex-wrap: wrap;
						float: right;
						&:first-child {
							border-top: 0;
							padding: 0;
							margin: 0;
							width: 120px !important;
							float: left;
						}
						div.th-title {
							display: block;
							position: absolute;
							left: 10px;
							width: 38%;
							padding-right: 10px;
							text-align: right;
							top: 50%;
							@include transform(translateY(-50%));
							font-weight: bold;
							text-transform: uppercase;
						}
						&.no-title {
							padding-left: 20px !important;
							text-align: center !important;
						}
					}
					.input-group-qty {
						display: inline-block;
						padding-right: 10px;
					}
				}
			}
		}
	}
	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.header-bottom {
		#topcontacts {
			justify-content: center;
			min-width: 133px;
			.worktime {
				display: none;
			}
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
		.nav-search {

		}
	}
	#powered {
		ul {
			&.links {
				margin-bottom: 40px;
			}
		}
		.inner {
			flex-direction: column;

			.copyright {
				order: 2;
				margin-bottom: 15px;
			}
			.payment {
				order: 1;

				.payment-img {
					max-width: 100%;
				}
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
	.category-tovar .row-inner,
	.page-checkout-buy .order {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 45px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
		& > div:first-child {
			margin-top: 20px;
		}
		br {
			display: none;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
	#powered {
		.inner {
			.payment {
				.payment-img {
					img {
						&:first-child {
							display: none;
						}
					}
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
}

@media (max-width: 570px) {
	.fancybox-lock {
		.fancy_holiday_works {
			width: 90% !important;
			.fancybox-skin {
				padding: 30px 10px !important;
				background-color: #fff;
				.holiday_table_wrap {
					font-size: 14px;
				}
				.holiday_table {
					td {
						font-size: 14px;
					}
				}
				.fancybox-outer {
					.fancybox-inner {
						width: 100% !important;
					}
				}
			}
		}
	}

	.holiday_works {
		margin-top: 0;
	}
	#powered > .container {
		padding: 0;
	}
	.breadcrumbs {
		padding: 15px;
		.container {
			padding: 0;
		}
	}
	.header-bottom {
		& > .container {
			height: auto;
			position: relative;
		}
		.logo-container {
			flex: 0 0 150px;
			margin: 10px 0;
		}
		#topcontacts {
			position: absolute;
			right: 160px;
			flex: 0 1 auto;
			min-width: unset;
		}
		#topcontacts, .headercart {
			flex: 0 1 auto;
		}
		.headercart {
			min-width: 133px;
		}
		.topcontacts {
			justify-content: center;
			max-width: 35px;
			min-width: auto;
			.media-title {
				padding: 0;
			}
			.media-body {
				display: none;
			}
			em {
				font-size: 30px;
			}
		}
	}
	#searchtop {
		border: 0;
		.input-group-btn {
			height: 30px;
			&:hover {
				background: $tc;
			}
		}
		.btn-search {
			line-height: 14px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			display: block;
		}
		.scroll-button {
			margin-bottom: 15px;
			margin-left: 15px;
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 130px;
			padding-right: 10px;
		}
		.headercart {
			min-width: 120px;
		}
		#topcontacts {
			right: 133px;
		}
	}
	.product-compare-header {
		padding-left: 0;width: 40px;
		.badge {
			right: 2px;
		}
	}
	#cart {
		.wrap-cart .badge {
			right: 7px;
		}
		.icon-cart {
			padding: 0 10px;
		}
	}
	.featured-cats {
		.featured-category-list {
			.category-list-item {
				.caption {
					a {
						font-size: 22px;
						line-height: normal;
					}
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: calc(100% - 12px);
		}
	}
	body .mfilter-free-container {
    width: 100%;
    margin-left: calc(-100% - 5px);
}
}
